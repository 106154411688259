<template>
  <el-container style="background:#fff;height: auto;overflow: overlay;flex-direction: column;">
    <el-row style="padding: 20px;flex-grow: 1;overflow: hidden overlay;">
      <el-row>
        <el-col :span="24" class="inside-menu-wrap" :class="navBarFixed == true ? 'navBarWrap' :''"
                style="height: 40px;"
        >
          <ul class="inside-menu">
            <li v-for="(item,index) in menulist" :key="index" :class="flag==index?'liActive':''"
                @click="chooseMenu(index)"
            ><a :href="item.href">{{ item.name }}</a></li>
          </ul>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="height: 40px;" v-show="navBarFixed == true">
        </el-col>
      </el-row>
      <el-col :span="24" class="title-bar" id="a01">物质基本信息</el-col>
      <el-form ref="form" status-icon :model="substance" label-width="100px" v-loading="loading">

        <el-form-item label="物质ID:" v-show="substance.id">
          <el-input v-model="substance.id" :disabled="true" style="width: 300px;float: left"/>
        </el-form-item>

        <el-form-item label="物质类型:">
          <el-select v-model="substance.type" placeholder="请选择物质类型" style="width: 300px;float: left">
            <el-option
              v-for="item in substanceTypeOptions"
              :key="item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in substance.dkbSubstanceName"
          :label="index==0?'物质名称:':''"
          :key="'substance-name-'+index"
          :rules="[
          { required: true, message: '请输入物质名称', trigger: 'blur'},
           { validator: validateSubstanceName, trigger: 'blur'}
          ]"

          :prop="'dkbSubstanceName.' + index + '.name'"
        >
          <el-input placeholder="请输入物质名称" v-model="item.name" style="width: 450px;float: left"
                    maxlength="300" clearable show-word-limit @blur="autoFillUnii(index)"
          />
          <el-select v-model="item.nameLangue" placeholder="请选择语言" style="width: 80px;float: left">
            <el-option
              v-for="item in languageConcep"
              :key="item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <el-form-item style="float: left;margin-bottom: 0px;"
                        :rules="[
          { required: true, message: '请选择名称类型', trigger: 'blur'},
           { validator: validateAliasType, trigger: 'blur'}
          ]"
                        :prop="'dkbSubstanceName.' + index + '.aliasType'"
          >
            <el-select v-model="item.aliasType" placeholder="请选择类型"
                       @change="autoFillUnii(index)"
                       style="width: 120px;float: left"
            >
              <el-option
                v-for="item in aliasTypeConcept"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>

          <i class="el-icon-remove" @click.prevent="delSubstanceName(item)"></i>
          <el-radio v-model="substance.namePrimary" :label="index" border size="small"
                    @change="primaryChange($event,'name',index)"
                    style="margin-right: 10px;padding: 8px 8px 0 5px;"
          >
           首选项
          </el-radio>
          <el-button type="text" @click="showResourceTable('dkb_substance_name','dkbSubstanceName',index,'name')">添加来源
          </el-button>
          <el-button type="primary" plain class="addData" style="width: 80px;padding:0px 0px 0px 16px;"
                     v-if="index == substance.dkbSubstanceName.length-1"
                     @click="addSubstanceName('中文')"
          >
            <i class="el-icon-circle-plus"
               style="color:#7C9DF7;width:16px;height:16px;display:inline-block;float: left"
            ></i>
            <svg-icon icon-class="china" style="width: 24px;height: 16px;"/>
          </el-button>
          <el-button type="primary" plain class="addData" style="width: 80px;padding:0px 0px 0px 16px;"
                     v-if="index == substance.dkbSubstanceName.length-1"
                     @click="addSubstanceName('英语')"
          >
            <i class="el-icon-circle-plus"
               style="color:#7C9DF7;width:16px;height:16px;display:inline-block;float: left"
            ></i>
            <svg-icon icon-class="yingguoguoqi" style="width: 24px;height: 16px;"/>
          </el-button>
        </el-form-item>

        <el-form-item
          v-for="(item, index) in substance.dkbSubstanceDesc"
          :label="index==0?'物质描述:':''"
          :key="'substance-desc-'+index"
          :prop="'dkbSubstanceDesc.' + index + '.substanceDesc'"
          :rules="[
            { required: true, message: '请输入物质描述', trigger: 'blur'},
             { validator: validateSubstanceDesc, trigger: 'blur'}
            ]"
        >
          <el-input class="subDescribe" type="textarea" :rows="6" placeholder="请输入物质描述" v-model="item.substanceDesc"
                    maxlength="200" clearable show-word-limit
                    style="width: 500px;float: left;"
          />
          <i class="el-icon-remove" @click.prevent="delSubstanceDesc(item)"></i>
          <el-radio v-model="substance.descPrimary" :label="index" border @change="primaryChange($event,'desc',index)">
            设置为推荐值
          </el-radio>
          <el-button type="text"
                     @click="showResourceTable('dkb_substance_desc','dkbSubstanceDesc',index,'substance_desc')"
          >添加来源
          </el-button>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" plain class="addData" style="width: 500px;"
                     @click="addSubstanceDesc"
          >
            <i class="el-icon-circle-plus el-icon--left"
               style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"
            ></i>
            添加物质描述
          </el-button>
        </el-form-item>


        <el-form-item label="关联物质:" prop="relevanceId">
          <el-select style="width: 500px;"
                     v-model="substance.relevanceId"
                     filterable
                     clearable
                     remote
                     reserve-keyword
                     placeholder="请输入关键字搜索"
                     :remote-method="queryRelevanceSubstance"
                     :loading="loading"
          >
            <el-option
              v-for="item in relevanceSubstanceList"
              :key="item.substanceId+'relevanceSubstanceList'"
              :label="item.name"
              :value="item.substanceId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-col :span="24" class="title-bar" id="a02">UNII物质信息</el-col>
        <el-row>
          <el-col :span="12" :offset="2" style="padding-bottom: 10px;">
            <el-button type="primary" icon="el-icon-search" style="background: #2B60F8"
                       @click="queryUnii"
            >UNII查询
            </el-button>
            <el-button type="warning" icon="el-icon-delete" plain
                       @click="clearUnii"
            >清除
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2" style="height: 180px"></el-col>
          <el-col :span="3" style="height: 180px">
            <div style="width: 150px;height: 150px;background: #EBF0FA;border: 1px solid #BDD3FF;">
              <img src="@/assets/images/no-image.jpg" alt="image" style="width: 100%;height: 100%"
                   v-if="!substance.dkbSubstanceStructure.structuralPicture"
              >
              <img :src="'data:image/png;base64,'+substance.dkbSubstanceStructure.structuralPicture"
                   alt="image" style="width: 100%;height: 100%"
                   v-if="substance.dkbSubstanceStructure.structuralPicture"
              >
            </div>
          </el-col>
          <el-col :span="19" style="height: auto">
            <el-row>
              <el-col :span="10">
                <el-form-item label="UNII:">
                  {{ substanceCode.unii }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="CAS:">
                  {{ substanceCode.cas }}
                  <i class="el-icon-edit cas-edit" v-show="substanceCode.unii && !substanceCode.cas"
                     @click="editCas(substance.id,substanceCode.unii)"></i>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item label="立体化学:">
                  {{ substance.dkbSubstanceStructure.stereochemistry }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="分子式:">
                  {{ substance.dkbSubstanceStructure.molecularFormula }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="10">
                <el-form-item label="旋光性:">
                  {{ substance.dkbSubstanceStructure.opticalActivity }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="分子量:">
                  {{ substance.dkbSubstanceStructure.molecularWeight }}
                </el-form-item>
              </el-col>
            </el-row>


          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" style="margin-left: 120px;">
            <el-form-item label="SMILES:">
              {{ substance.dkbSubstanceStructure.smiles }}
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 120px;">
            <el-form-item label="INCHI_KEY:">
              {{ substance.dkbSubstanceStructure.inchiKey }}
            </el-form-item>
          </el-col>
          <el-col :span="20" style="margin-left: 120px;">
            <el-form-item label="INCHI:">
              {{ substance.dkbSubstanceStructure.inchi }}
            </el-form-item>
          </el-col>
        </el-row>


        <el-col :span="24" class="title-bar" id="a03">物质属性</el-col>

        <el-form-item label="" prop="tableName" id="recommendProps">
          <el-button
            v-for="item in substanceProps"
            :key="'substancePropsButton-'+item.conceptId"
            plain
            @click="addSubstanceProp($event,item.conceptId)"
          >{{ item.conceptVal }}
          </el-button>

        </el-form-item>


        <el-col :span="24" style="margin-left: 80px;padding-bottom: 10px;font-size: 14px;">
          <div style="width: 200px;float: left;text-align: center">物质属性名称</div>
          <div style="width: 200px;float: left;text-align: center">物质属性值</div>
        </el-col>


        <el-col :span="18">


          <el-form-item
            v-for="(item, index) in substance.dkbSubstanceProps"
            :key="'substance-prop-'+index"
            :prop="'dkbSubstanceProps.' + index + '.name'"
            :rules="[
             { validator: validateSubstanceProps, trigger: 'blur'}
            ]"
          >
            <el-select v-model="item.name" placeholder="请选择物质属性名称"
                       style="width: 200px;float: left"
                       @change="propsNameSelectChange($event,index)"

            >
              <el-option
                v-for="item in substanceProps"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
            <el-select v-model="item.value" placeholder="请选择物质属性值"
                       :disabled="item.name===null?true:false"
                       style="width: 200px;float: left"
                       filterable
                       :filter-method="((val)=>{substancePropsValFilter(val,substance.dkbSubstanceProps[index].name)})"
                       @focus=" substancePropsValOptions[substance.dkbSubstanceProps[index].name]= substancePropsVal[substance.dkbSubstanceProps[index].name]"
            >
              <el-option
                v-for="item in substancePropsValOptions[substance.dkbSubstanceProps[index].name]"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
            <i class="el-icon-remove" @click.prevent="delSubstanceprop(item)"></i>
            <el-button type="text" @click="showResourceTable('dkb_substance_props','dkbSubstanceProps',index,'name')">
              添加来源
            </el-button>
          </el-form-item>


          <el-form-item>
            <el-button type="primary" plain class="addData" style="width: 400px;"
                       @click="addSubstanceProp"
            >
              <i class="el-icon-circle-plus el-icon--left"
                 style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"
              ></i>
              添加
            </el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="20" class="bottom-bar"
              style="height: 80px;padding-right: 0px;position: fixed;right: 30px;bottom: 10px;"
      >
        <el-button style="float: right;margin-left: 10px;" @click="editNext" v-show="substance.id">下一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="editPrev" v-show="substance.id">上一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="returnPageNoRefresh">返回</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(1)">保存</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(2)">保存并关闭</el-button>
      </el-col>
    </el-row>
    <el-dialog title="UNII物质检索" v-dialogDrag width="60%" :visible.sync="unii.uniiTableVisible" :close-on-click-modal="false">
      <el-form ref="uniiQueryForm" status-icon :model="unii.form">
        <el-form-item>
          <el-select v-model="unii.form.queryType" style="width: 120px;float: left"
                     title="搜索匹配方式仅适用于别名,unii和cas编码都是精确匹配"
          >
            <el-option
              v-for="item in queryTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input placeholder="请输入unii编码/cas编码/别名" v-model="unii.form.searchValue" maxlength="250" clearable
                    show-word-limit style="width: 400px;float: left" @keydown.enter.native="queryUnii"
          />
          <el-input style="display: none"/>
          <el-button type="primary" icon="el-icon-search" style="margin-left: 20px;background: #2B60F8"
                     @click="queryUnii"
          >检索
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="loading"
        :data="unii.uniiTableData"
        :height="300"
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
      >
        <el-table-column property="unii" label="unii编码" width="150"></el-table-column>
        <el-table-column property="cas" label="CAS" width="100"></el-table-column>
        <el-table-column property="displayName" label="名称"></el-table-column>
        <el-table-column property="displayName" label="别名">
          <template slot-scope="scope">
            <el-popover trigger="click" placement="top" width="400">
              <p v-for="(item,index) in scope.row.aliasList" :key="'unii'+scope.row.id+'_'+index">
                {{ item.name }}
              </p>
              <span slot="reference" class="name-wrapper">
                  <span style="color: #2B60F8;font-weight: 700;" title="点击查看全部别名">
                    {{ scope.row.aliasList ? scope.row.aliasList[0].name : '' }}
                  </span>
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="chooseUniiData(scope.$index, scope.row)"
            >选择
            </el-button>
            <!--            type="primary" icon="el-icon-success"-->
          </template>
        </el-table-column>
        <template slot="empty" v-if="isUnii === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
      <pagination
        v-show="unii.total>0"
        :total="unii.total"
        :page.sync="unii.form.pageNum"
        :limit.sync="unii.form.pageSize"
        @pagination="queryUnii"
      />
    </el-dialog>

    <el-dialog title="引用来源管理" v-dialogDrag :visible.sync="resourceTableVisible"
               :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button style="float: right;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableData">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="resourceTableData.list"
        >
          <el-table-column property="date" label="引用资源类型">
            <template slot-scope="scope">
              <el-form-item
                :prop="'list.' + scope.$index + '.'+'resourceTypeId'"

                :rules="[{ required: true, message: '请选择物质名称', trigger: 'blur'}]"
              >
                <!--              :prop="`list.${scope.$index}.resourceTypeId`"
                 :rules="[{ required: true, message: '请选择物质名称', trigger: 'blur'}]"
                -->
                <el-select
                  v-model="scope.row.resourceTypeId"
                  placeholder="引用资源类型"
                  @change="resourceTypeChange($event,scope.$index)"
                  size="small"
                  style="width: 200px"
                >
                  <el-option
                    v-for="dict in resourceTypeOption"
                    :key="'resourceTypeOption'+dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>

            </template>
          </el-table-column>
          <el-table-column property="name" align="center" label="引用资源名称">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.resourceId"
                filterable
                remote
                style="width: 300px"
                reserve-keyword
                placeholder="请输入关键词"
                @change="resourceNameChange($event,scope.$index)"
                :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
                :loading="loading"
                :disabled="!scope.row.resourceTypeId?true:false"
              >
                <el-option
                  v-for="item in resourceDataOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in [scope.row]"
                  :key="item.resourceId+'original'"
                  :label="item.resourceName"
                  :value="item.resourceId"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="100">
            <template slot-scope="scope">

              <el-button
                size="mini"
                type="text"
                style="color: #FF3333;"
                @click="handleResourceDelete(scope.$index,scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
        </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button type="primary" style="float: right;margin-right: 10px;" icon="el-icon-circle-check"
                     size="small" @click="saveResource"
          >确定
          </el-button>
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-close"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>


  </el-container>

</template>
<script>
import {
  selectUniiOrNames,
  selectUniiRecord,
  saveOrUpdate,
  info,
  checkSubstanceName,
  selectSubstanceNameList,
  list,
  updateCas
} from '@/api/dkm/substance'
import { resourceNameQuery, saveOrUpdateInfoList, selectReferenceInfo } from '@/api/dkm/resource'
import { getResourceCategory } from '@/utils/resourceReference'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { isEmpty } from '@/utils/common'
import { saveOrUpdate as saveOrUpdateDrug, oligomictDrugInfo } from '@/api/dkm/drug'
import { deepClone } from '@/utils/index'
import $ from 'jquery'
import '@/utils/nav.js'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  name: 'SubstanceEdit',
  data() {
    return {
      conceptConst,
      loading: false,
      substance: {
        type: null,
        dkbSubstanceName: [],
        namePrimary: 0,
        dkbSubstanceDesc: [],
        descPrimary: 0,
        relevanceId: undefined,
        /*物质结构信息*/
        dkbSubstanceStructure: {
          id: null,
          substanceId: null,
          stereochemistry: '',
          opticalActivity: '',
          molecularFormula: '',
          molecularWeight: '',
          structuralPicture: '',
          inchi: '',
          inchiKey: '',
          smiles: '', molFile: '', mol3dFile: ''
        },
        /*物质代码信息*/
        dkbSubstanceCode: [],
        /*物质属性信息*/
        dkbSubstanceProps: []
      },
      /*关联物质下拉列表*/
      relevanceSubstanceList: [],
      /*物质类型下拉列表*/
      substanceTypeOptions: [],
      /*名称类型下拉列表*/
      aliasTypeConcept: [],
      aliasTypeConceptMap: new Map(),
      /*语言概念下拉列表*/
      languageConcep: [],
      languageConceptMap: new Map(),
      /*物质属性名称概念字典*/
      substanceProps: [],
      /*物质属性值概念字典*/
      substancePropsVal: {},
      substancePropsValOptions: {},
      unii: {
        /*unii检索数据*/
        form: {
          searchValue: undefined,
          queryType: '2',
          pageNum: 1,
          pageSize: 10
        },
        /*总条数*/
        total: 0,
        /*unii检索表格数据*/
        uniiTableData: [],
        /*unii检索表格弹是否显示*/
        uniiTableVisible: false
      },
      resourceTypeOption: [{ id: 1, typeName: '说明书' }, { id: 2, typeName: '药典' }],
      resourceDataOption: [],
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /* 来源引用表格数据*/
      resourceTableData: {
        list: []
      },
      /* 当前编辑来源引用弹窗临时记录信息*/
      nowEditResourceData: {
        sourceTable: undefined,
        sourceTableField: undefined,
        sourceTableId: undefined,
        fieldName: undefined,
        fieldIndex: undefined
      },
      isUnii: false,
      queryTypeOptions: [
        { value: '2', label: '精确匹配' },
        { value: '1', label: '模糊匹配' },
        { value: '3', label: '前缀匹配' }
      ],
      flag: 0,
      menulist: [
        {
          href: '#a01',
          name: '物质基本信息'
        },
        {
          href: '#a02',
          name: 'UNII物质信息'
        },
        {
          href: '#a03',
          name: '物质属性'
        }
      ],
      navBarFixed: false,
      /*列表页面查询参数*/
      queryParams: {},
      /*存储根据查询获取到的数据，用于翻页*/
      pageData: []

    }
  },
  mounted: function() {
    // 事件监听滚动条
    window.addEventListener('scroll', this.watchScroll)
  },
  created() {
    this.loading = true
    //获取引用资源类型
    getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })

    const id = this.$route.params && this.$route.params.id
    if (id) {
      this.queryParams = getCache('substanceQueryParams')
      this.pageData = getCache('substancePageData')
      if (!this.queryParams) {
        this.queryParams = {}
      }
      if (!this.queryParams.pageNum) {
        this.queryParams.pageNum = 1
      }
      if (!this.queryParams.pageSize) {
        this.queryParams.pageSize = 10
      }
      this.init().then(result => {
        this.getSubstanceInfo(id)
        this.loading = false
      })

    } else {
      this.init().then(result => {
        this.addSubstanceName('中文')
        //this.addSubstanceProp()
        //物质类型，设置默认值
        if (this.substanceTypeOptions && this.substanceTypeOptions.length > 0) {
          this.substance.type = this.substanceTypeOptions[0].conceptId
        }
        this.loading = false
      })

    }
    // this.scrollFn()
  },
  computed: {
    /*计算属性--获取cas编码和unii编码*/
    substanceCode() {
      let code = {}
      this.substance.dkbSubstanceCode.some((item, i) => {
        if (item.codeSysName === 'unii') {
          code.unii = item.substanceCode
        }
        if (item.codeSysName === 'cas') {
          code.cas = item.substanceCode
        }
        if (code.unii && code.cas) {
          return true
        }

      })
      return code
    }
  },
  methods: {
    watchScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop)
      //  当滚动超过 50 时，实现吸顶效果
      if (scrollTop > 40) {
        this.navBarFixed = true
      } else {
        this.navBarFixed = false
      }
    },
    chooseMenu(index) {
      this.flag = index
    },
    scrollFn() {
      $(document).ready(function() {
        // var navHeight = $(".inside-menu-wrap").offset().top;
        // console.log(navHeight)
        var navHeight = $('.inside-menu-wrap').offset().top
        // console.log(navHeight)
        var navFix = $('.inside-menu-wrap')
        $(window).scroll(function() {
          // console.log(navHeight)
          // console.log($(this).scrollTop())
          if ($(this).scrollTop() >= navHeight) {
            //console.log($(this).scrollTop())
            navFix.addClass('menuFix')

          } else {
            navFix.removeClass('menuFix')
            $('.inside-menu li a.active').removeClass('active')
          }
        })
      })

      //内容信息导航锚点
      $('.inside-menu-wrap').navScroll({
        scrollSpy: true
      })
    },

    /*初始化--加载字典等*/
    async init() {
      /*获取语言概念字典*/
      await getConcept(conceptConst.PUB_LANG, 0).then(result => {
        this.languageConcep = result
        this.languageConcep.forEach(item => {
          this.languageConceptMap.set(item.conceptId, item.conceptVal)
        })
      })
      /*获取物质属性概念字典*/
      await getConcept(conceptConst.DKB_SUBSTANCE_PROPS, 1).then(result => {
        this.substanceProps = result
        /* let propsVal = this.substancePropsVal
         let propsValOptions = this.substancePropsValOptions*/
        let that = this
        result.forEach(function(item, index) {
          that.substancePropsVal[item.conceptId] = item.sonDictTermConcept
          //that.substancePropsValOptions[item.conceptId] = item.sonDictTermConcept
          // 在JS中操作原本不存在的属性时，使用 Vue.set(object, propertyName, value) 方法向嵌套对象添加响应式属性
          that.$set(that.substancePropsValOptions, item.conceptId, item.sonDictTermConcept)
        })
      })
      /*获取物质类型字典*/
      await getConcept(conceptConst.DKB_SUBSTANCE_TYPE, 0).then(result => {
        this.substanceTypeOptions = result
      })

      /*获取名称类型字典*/
      await getConcept(conceptConst.PUB_NAME_TYPE, 0).then(result => {
        this.aliasTypeConcept = result
        this.aliasTypeConcept.forEach(item => {
          this.aliasTypeConceptMap.set(item.conceptId, item.conceptVal)
        })
      })
    },
    /*添加物质名称输入表单组件*/
    addSubstanceName(type) {
      let defaultVal
      if (this.languageConcep && this.languageConcep.length > 0) {
        this.languageConcep.forEach(item => {
          if (item.conceptVal == type) {
            defaultVal = item.conceptId
          }
        })
        //defaultVal = this.languageConcep[0].conceptId
      }
      this.substance.dkbSubstanceName.push({
        value: '',
        nameLangue: defaultVal,
        dkbReferenceInfos: [],
        aliasType: type=='英语'?conceptConst.PUB_NAME_TYPE_EN:conceptConst.PUB_NAME_TYPE_GENERIC,
        isPrimary: this.substance.dkbSubstanceName.length == 0 ? '1' : '0'
      })
      if (this.substance.dkbSubstanceName.length == 1) {
        //添加组件时，默认第一个选中为默认
        this.substance.namePrimary = 0
      }

    },
    /*移除物质名称输入表单组件*/
    delSubstanceName(item) {
      var index = this.substance.dkbSubstanceName.indexOf(item)
      if (this.substance.dkbSubstanceName.length > 1) {
        this.substance.dkbSubstanceName.splice(index, 1)
      } else {
        this.$message('物质名称至少保留一个！')
      }
      // 删除后如果没有默认值了，取第一个作为默认值
      let primary = -1
      this.substance.dkbSubstanceName.forEach(function(item, index) {
        if (item.isPrimary === '1') {
          primary = index
          return
        }
      })
      if (primary === -1) {
        //删除后没有默认值了
        this.substance.dkbSubstanceName[0].isPrimary = '1'
        this.substance.namePrimary = 0
      }

    },
    /*添加物质描述输入表单组件*/
    addSubstanceDesc() {
      this.substance.dkbSubstanceDesc.push({
        value: '',
        dkbReferenceInfos: [],
        isPrimary: this.substance.dkbSubstanceDesc.length == 0 ? '1' : '0'
      })
      if (this.substance.dkbSubstanceDesc.length == 1) {
        //添加组件时，默认第一个选中为默认
        this.substance.descPrimary = 0
      }

    },
    /*移除物质描述输入表单组件*/
    delSubstanceDesc(item) {
      var index = this.substance.dkbSubstanceDesc.indexOf(item)
      this.substance.dkbSubstanceDesc.splice(index, 1)
      // 删除后如果没有默认值了，取第一个作为默认值
      let primary = -1
      this.substance.dkbSubstanceDesc.forEach(function(item, index) {
        if (item.isPrimary === '1') {
          primary = index
          return
        }
      })
      if (primary === -1 && this.substance.dkbSubstanceDesc.length > 0) {
        //删除后没有默认值了
        this.substance.dkbSubstanceDesc[0].isPrimary = '1'
        this.substance.descPrimary = 0
      }
    },
    /*添加物质属性输入表单组件*/
    addSubstanceProp($event, name) {
      let select = this.substance.dkbSubstanceProps
      //属性名称-设置默认值
      if (isEmpty(name) && this.substanceProps && this.substanceProps.length > 0) {
        name = this.substanceProps[0].conceptId
      }
      // 还原下拉列表
      this.substancePropsValOptions[name] = this.substancePropsVal[name]
      let val
      if (this.substancePropsVal[name] && this.substancePropsVal[name].length > 0) {
        val = this.substancePropsVal[name][0].conceptId
      }

      select.push({
        name: name,
        value: val
      })

    },
    /*移除物质属性输入表单组件*/
    delSubstanceprop(item) {
      var index = this.substance.dkbSubstanceProps.indexOf(item)
      this.substance.dkbSubstanceProps.splice(index, 1)

    },
    /** 保存按钮--新增物质 */
    submitForm(type) {
      this.$refs['form'].validate(valid => {
        if (valid) {
          saveOrUpdate(this.substance).then(response => {
            this.msgSuccess(response.msg)
            //调用刷新表格数据的方法
            AcrossUtil.$emit('tableReload');
            if (type == 2) {
              this.returnPageNoRefresh()
            }else{
              if(response.data){
                //保存完成后根据id跳转到编辑界面
                this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
                  this.$nextTick(() => {
                    this.$router.replace({
                      path: '/redirect' + '/substance/edit/' + response.data
                    })
                  })
                })
              }
            }
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },

    /*返回并刷新物质列表页面*/
    returnPage() {
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route)
      this.$store.dispatch('tagsView/delCachedView', { name: 'Substance' }).then(res => {
        this.$router.push('/dkm/substance')
      })
    },
    /*返回物质列表页面，不刷新*/
    returnPageNoRefresh() {
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push('/dkm/substance')
    },
    /*根据物质id获取物质明细*/
    getSubstanceInfo(id) {
      var that = this
      info(id).then(response => {
        this.substance.id = response.data.id
        this.substance.substanceId = response.data.substanceId
        this.substance.type = response.data.type
        this.substance.relevanceId = response.data.relevanceId
        this.substance.relevanceName = response.data.relevanceName
        this.substance.dkbSubstanceName = response.data.dkbSubstanceName
        this.substance.dkbSubstanceDesc = response.data.dkbSubstanceDesc
        this.substance.dkbSubstanceStructure = response.data.dkbSubstanceStructure
        this.substance.dkbSubstanceCode = response.data.dkbSubstanceCode
        this.substance.dkbSubstanceProps = response.data.dkbSubstanceProps
        if (!this.substance.dkbSubstanceStructure) {
          this.substance.dkbSubstanceStructure = {}
        }
        this.relevanceSubstanceList.push({
          substanceId: this.substance.relevanceId,
          name: this.substance.relevanceName
        })

        /*设置首选项单选按钮*/
        this.setPrimary('name')
        this.setPrimary('type')
      })
    },
    /*unii编辑*/
    queryUnii() {
      this.isUnii = true
      this.loading = true
      this.unii.uniiTableVisible = true
      selectUniiOrNames(this.unii.form).then(response => {
        this.unii.uniiTableData = response.rows
        this.unii.uniiTableData.forEach(item => {
          if (item.aliasList && item.aliasList.length > 0) {
            item.displayName = item.aliasList[0].displayName
          }
        })
        this.unii.total = response.total
        this.loading = false
      })
    },
    //清理UNii信息
    clearUnii(){
      this.substance.dkbSubstanceStructure={}
      this.substance.dkbSubstanceCode = []
    },

    /*unii检索表格，选中数据*/
    chooseUniiData(index, row) {
      //根据unii查询物质结构信息
      selectUniiRecord(row.unii).then(response => {
        this.unii.uniiTableVisible = false
        if (response.data.length > 0) {
          let row = response.data[0]
          let unii = this.substance.dkbSubstanceStructure
          unii.stereochemistry = row.stereochemistry
          unii.opticalActivity = row.opticalActivity
          unii.molecularFormula = row.molecularFormula
          unii.molecularWeight = row.molecularWeight
          unii.structuralPicture = row.structuralPicture
          unii.inchi = row.inchi
          unii.inchiKey = row.inchiKey
          unii.smiles = row.smiles
          unii.molFile = row.molFile
          unii.mol3dFile = row.mol3dFile
          this.substance.dkbSubstanceCode = []
          this.substance.dkbSubstanceCode.push({ codeSysId: '', codeSysName: 'unii', substanceCode: row.unii })
          this.substance.dkbSubstanceCode.push({ codeSysId: '', codeSysName: 'cas', substanceCode: row.cas })
        } else {
          this.$message('根据unii未找到物质结构信息')
        }

      })
    },
    /*设置首选项单选按钮*/
    setPrimary(type) {
      let field = type === 'name' ? 'dkbSubstanceName' : 'dkbSubstanceDesc'
      let primaryField = type === 'name' ? 'namePrimary' : 'descPrimary'
      let substance = this.substance
      substance[field].forEach(function(item, index) {
        if (item.isPrimary === '1') {
          substance[primaryField] = index
          return
        }
      })
    },
    primaryChange(value, type, index) {
      let field = type === 'name' ? 'dkbSubstanceName' : 'dkbSubstanceDesc'
      let primaryField = type === 'name' ? 'namePrimary' : 'descPrimary'
      this.substance[primaryField] = index
      this.substance[field].forEach(function(item, index) {
        if (index === value) {
          item.isPrimary = '1'
        } else {
          item.isPrimary = '0'
        }
      })
    },
    /*属性名称下拉列表选项变化*/
    propsNameSelectChange(value, index) {
      // 清空关联的属性值下拉列表选项
      this.substance.dkbSubstanceProps[index].value = ''
      let val
      if (this.substancePropsVal[value] && this.substancePropsVal[value].length > 0) {
        val = this.substancePropsVal[value][0].conceptId
      }
      // 关联的属性值下拉列表选项赋值为第一个选项
      this.substance.dkbSubstanceProps[index].value = val

    },
    /*显示来源引用表格*/
    showResourceTable(tableName, fieldName, index, field) {
      let row = this.substance[fieldName][index]
      this.resourceTableData.list = []
      this.nowEditResourceData.fieldName = fieldName
      this.nowEditResourceData.fieldIndex = index
      this.nowEditResourceData.sourceTable = tableName
      this.nowEditResourceData.sourceTableField = field
      this.nowEditResourceData.sourceTableId = row.id
      Object.assign(this.resourceTableData.list, row.dkbReferenceInfos || [])
      this.resourceTableVisible = true
    },
    /*新增一条引用来源*/
    addResource() {
      this.resourceTableData.list.push({
        sourceTable: this.nowEditResourceData.sourceTable,
        sourceTableField: this.nowEditResourceData.sourceTableField,
        sourceTableId: this.nowEditResourceData.sourceTableId,
        resourceTypeId: null,
        resourceId: null
      })
    },
    resourceNameQuery(query, resourceTypeId) {
      if (!isEmpty(query)) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          /*this.options = this.list.filter(item => {
            return item.label.toLowerCase()
              .indexOf(query.toLowerCase()) > -1;
          });*/
          resourceNameQuery(resourceTypeId, query.trim()).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableData.list.splice(index, 1)

    },
    /*来源引用表格-资源类型下拉列表变化*/
    resourceTypeChange(value, tableRowIndex) {
      this.resourceDataOption = []
      let item = this.resourceTableData.list[tableRowIndex]
      item.resourceId = undefined
      item.resourceName = undefined
    },
    /*来源引用表格-资源名称下拉列表变化*/
    resourceNameChange(value, tableRowIndex) {
      let obj = {}
      obj = this.resourceDataOption.find((item) => {
        return item.id === value
      })
      this.resourceTableData.list[tableRowIndex].resourceId = obj.id
      this.resourceTableData.list[tableRowIndex].resourceName = obj.name
      this.resourceDataOption = []
    },
    /* 保存来源引用*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {

          let fieldName = this.nowEditResourceData.fieldName
          let index = this.nowEditResourceData.fieldIndex
          let list = this.substance[fieldName][index].dkbReferenceInfos
          this.$set(this.substance[fieldName][index], 'dkbReferenceInfos', this.resourceTableData.list)
          this.resourceTableVisible = false
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
    /* 物质名称-重复性校验*/
    validateSubstanceName(rule, value, callback, index) {
      if (!isEmpty(value)) {
        // 调用接口校验
        checkSubstanceName(value, this.substance.id).then(response => {
          let item = response.data && response.data.length > 0 ? response.data[0] : undefined
          if (item) {
            callback(new Error('物质名称已存在,物质id为[' + item.substanceId + ']'))
          } else {
            // 页面表单校验，
            let substanceNameList = this.substance.dkbSubstanceName
            let key = Object.keys(index)[0]
            let nameIndex = key.replaceAll('dkbSubstanceName.', '').replaceAll('.name', '')
            let nowName = substanceNameList[nameIndex]
            if (substanceNameList) {
              substanceNameList.forEach(function(j, i) {
                //名称+名称语言+名称类型完全一样算重复
                if (i != nameIndex && j.name && j.name.trim() === value.trim() && j.nameLangue === nowName.nameLangue && j.aliasType === nowName.aliasType) {
                  callback(new Error('物质名称已存在'))
                }
              })
            }

            callback()
          }
        })
      } else {
        callback()
      }
    },
    /* 名称类型-校验*/
    validateAliasType(rule, value, callback, index) {
      if (!isEmpty(value)) {
        let allName=this.substance.dkbSubstanceName
        let englishNameSize=0
        let genericNameSize=0
        allName.forEach(function(item, i) {
          if(item.aliasType==conceptConst.PUB_NAME_TYPE_EN){
            englishNameSize++
          } else if(item.aliasType==conceptConst.PUB_NAME_TYPE_GENERIC){
            genericNameSize++
          }
        })
        if(englishNameSize>1){
          callback(new Error('英文名只能有一个'))
        }else if(genericNameSize>1){
          callback(new Error('通用名只能有一个'))
        }else{
          callback()
        }

      }else {
        callback()
      }

    },
    /* 物质描述-页面重复性校验*/
    validateSubstanceDesc(rule, value, callback, index) {
      if (!isEmpty(value)) {
        // 页面表单校验，
        let dkbSubstanceDesc = this.substance.dkbSubstanceDesc
        let key = Object.keys(index)[0]
        let nameIndex = key.replaceAll('dkbSubstanceDesc.', '').replaceAll('.substanceDesc', '')
        if (dkbSubstanceDesc) {
          dkbSubstanceDesc.forEach(function(j, i) {
            if (i != nameIndex && j.substanceDesc && j.substanceDesc.trim() === value.trim()) {
              callback(new Error('物质描述已存在'))
            }
          })
        }
        callback()
      } else {
        callback()
      }
    },
    /* 物质属性-重复性校验*/
    validateSubstanceProps(rule, value, callback, index) {
      let propsList = this.substance.dkbSubstanceProps
      let key = Object.keys(index)[0]
      let nowIndex = key.replaceAll('dkbSubstanceProps.', '').replaceAll('.name', '')
      let now = propsList[nowIndex]
      propsList.forEach(function(item, index) {
        if (index != nowIndex && item.name == now.name && item.value == now.value) {
          callback(new Error('属性已存在,请勿重复添加'))
        }
      })
      callback()
    },
    /*物质属性值下拉搜索过滤*/
    substancePropsValFilter(query, name) {
      if (query !== '') {
        this.substancePropsValOptions[name] = this.substancePropsVal[name].filter(item => {
          return item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
        })

      } else {
        this.substancePropsValOptions[name] = this.substancePropsVal[name]
      }
    },
    /*快捷新增物质--根据药品名称填充物质名称*/
    fillSubstanceName(dkbDrugAliasList) {
      this.substance.dkbSubstanceName = []
      dkbDrugAliasList.forEach((item, index) => {
        //处理首选项
        if (item.isPrimary === '1') {
          this.substance.namePrimary = index
        }
        //处理来源引用
        if (item.dkbReferenceInfoList) {
          item.dkbReferenceInfoList.forEach(r => {
            r.id = undefined
            r.sourceTable = 'dkb_substance_name'
            r.sourceTableId = undefined
          })
        }

        this.substance.dkbSubstanceName.push({
          name: item.alias,
          nameLangue: item.aliasLangue,
          isPrimary: item.isPrimary,
          dkbReferenceInfos: item.dkbReferenceInfoList
        })
      })
    },
    /*快捷新增物质--根据药品描述填充物质描述*/
    fillSubstanceDesc(dkbDrugDescList) {
      this.substance.dkbSubstanceDesc = []
      dkbDrugDescList.forEach((item, index) => {
        //处理首选项
        if (item.isPrimary === '1') {
          this.substance.descPrimary = index
        }
        //处理来源引用
        if (item.dkbReferenceInfoList) {
          item.dkbReferenceInfoList.forEach(r => {
            r.id = undefined
            r.sourceTable = 'dkb_substance_desc'
            r.sourceTableId = undefined
          })
        }
        this.substance.dkbSubstanceDesc.push({
          substanceDesc: item.drugDesc,
          isPrimary: item.isPrimary,
          dkbReferenceInfos: item.dkbReferenceInfoList
        })
      })
    },

    /*搜索关联物质*/
    async queryRelevanceSubstance(query) {
      if (!isEmpty(query)) {
        //this.loading = true
        let res = await selectSubstanceNameList(query.trim())
        if (res.code === 200) {
          //this.loading = false
          this.relevanceSubstanceList = res.data
        }
      } else {
        this.relevanceSubstanceList = []
      }
    },
    async editNext() {
      //console.log("物质editNext",this.pageData,this.queryParams)
      if (this.pageData == undefined) {
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res => {
          this.$router.push('/dkm/substance')
          return
        })
      }
      let nowIndex = -1
      let id = this.substance.id
      this.pageData.some((item, index) => {
        if (item.id == id) {
          nowIndex = index
          return true
        }
      })
      if (nowIndex > -1 && nowIndex < this.pageData.length - 1) {
        id = this.pageData[nowIndex + 1].id
      } else {
        //查询下一页数据
        this.queryParams.pageNum++
        //向上取整
        let maxPage = Math.ceil(this.queryParams.total / this.queryParams.pageSize)
        if (this.queryParams.pageNum > maxPage) {
          this.msgInfo('已经是最后一条记录了')
          this.queryParams.pageNum--
          return
        }
        let response = await list(this.queryParams)
        this.queryParams.total = response.total
        setCache('substanceQueryParams', this.queryParams)
        setCache('substancePageData', response.rows)
        this.pageData = response.rows
        id = this.pageData[0].id
      }
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route).then(res => {
        oligomictDrugInfo(id).then(response => {
          if (response.data) {
            //存在只含有该物质的药物,直接跳转到药物编辑界面
            this.$router.push('/drug/editOligomictDrug/' + response.data.id)
          } else {
            //跳转到物质编辑界面
            this.$router.push('/substance/edit/' + id)
          }
        })

      })
    },
    async editPrev() {
      //console.log("物质editPrev",this.pageData,this.queryParams)
      if (this.pageData == undefined) {
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res => {
          this.$router.push('/dkm/substance')
          return
        })
      }
      let nowIndex = -1
      let id = this.substance.id
      this.pageData.some((item, index) => {
        if (item.id == id) {
          nowIndex = index
          return true
        }
      })
      //console.log(id,this.pageData,nowIndex,"-----",this.queryParams,this.queryParams.pageNum)
      if (nowIndex > 0) {
        id = this.pageData[nowIndex - 1].id
      } else {
        //查询上一页数据
        this.queryParams.pageNum--
        if (this.queryParams.pageNum < 1) {
          this.msgInfo('已经是第一条记录了')
          this.queryParams.pageNum++
          return
        }
        let response = await list(this.queryParams)
        this.queryParams.total = response.total
        setCache('substanceQueryParams', this.queryParams)
        setCache('substancePageData', response.rows)
        this.pageData = response.rows
        id = this.pageData[response.rows.length - 1].id
      }
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route).then(res => {
        oligomictDrugInfo(id).then(response => {
          if (response.data) {
            //存在只含有该物质的药物,直接跳转到药物编辑界面
            this.$router.push('/drug/editOligomictDrug/' + response.data.id)
          } else {
            //跳转到物质编辑界面
            this.$router.push('/substance/edit/' + id)
          }
        })
      })

    },
    /*根据物质英文名称-自动填充unii信息*/
    autoFillUnii(index) {
      let nameItem = this.substance.dkbSubstanceName[index]
      //只有新增时，且有英文名的时候，才匹配
      if (!this.substance.id && nameItem.aliasType == conceptConst.PUB_NAME_TYPE_EN && !isEmpty(nameItem.name)) {
        this.unii.form.searchValue = nameItem.name
        selectUniiOrNames(this.unii.form).then(response => {
          if (response.rows && response.rows.length > 0) {
            this.chooseUniiData(0, response.rows[0])
          }
        })
      }
    },
    //编辑UNII对应的cas信息
    editCas(substanceId,unii){
      this.$prompt('请输入cas编码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if(isEmpty(value)){
          this.msgInfo("cas编码不能为空")
          return
        }else if(value.length>20){
          this.msgInfo("cas编码长度不能超过20")
          return
        }
        updateCas(substanceId,unii,value).then(res=>{
          this.substance.dkbSubstanceCode.some(item=>{
            if (item.codeSysName === 'cas') {
              item.substanceCode=value
              return true
            }
          })
          this.msgSuccess()
        })
      }).catch(() => {
      });
    }

  }
}
</script>


<style lang="scss" scoped>

/*分类标题*/
.title-bar {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}

/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}

/*物质编码div*/
#substanceCodeDiv {
  width: 100%;
  /*height: 155px;*/
  background: #EBF0FA;
  border: 1px solid #BDD3FF;
  border-radius: 4px;
  padding: 10px 20px;
  float: left;
  position: relative;
}

#substanceCodeDiv p {
  height: 14px;
  line-height: 14px;
  padding: 5px 0px;
}

#substanceCodeDiv label {
  display: block;
  float: left;
  width: 80px;
  text-align: right;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #666666;
  margin-left: 20px;
}

#substanceCodeDiv span {
  width: 700px;
  display: block;
  float: left;
  padding-left: 5px;
  font-size: 14px;
  line-height: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}

.bottom-bar {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 60px;
  z-index: 200;
  font-size: 12px;
  line-height: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  padding-right: 260px;
}

/*推荐属性*/
#recommendProps button {
  min-width: 100px;
  color: #2B60F8
}

.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

.el-form-item {
  margin-bottom: 18px;
}

.navBarWrap {
  position: fixed;
  top: 50px;
  z-index: 100;
}

.cas-edit{
  color: #4B56F2;
  cursor: pointer;
}
.cas-edit:hover{
  color: #7b82f6;
}
</style>
