var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      staticStyle: {
        background: "#fff",
        height: "auto",
        overflow: "overlay",
        "flex-direction": "column",
      },
    },
    [
      _c(
        "el-row",
        {
          staticStyle: {
            padding: "20px",
            "flex-grow": "1",
            overflow: "hidden overlay",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticClass: "inside-menu-wrap",
                  class: _vm.navBarFixed == true ? "navBarWrap" : "",
                  staticStyle: { height: "40px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "inside-menu" },
                    _vm._l(_vm.menulist, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class: _vm.flag == index ? "liActive" : "",
                          on: {
                            click: function ($event) {
                              return _vm.chooseMenu(index)
                            },
                          },
                        },
                        [
                          _c("a", { attrs: { href: item.href } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.navBarFixed == true,
                    expression: "navBarFixed == true",
                  },
                ],
                staticStyle: { height: "40px" },
                attrs: { span: 24 },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "title-bar", attrs: { span: 24, id: "a01" } },
            [_vm._v("物质基本信息")]
          ),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "form",
              attrs: {
                "status-icon": "",
                model: _vm.substance,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.substance.id,
                      expression: "substance.id",
                    },
                  ],
                  attrs: { label: "物质ID:" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px", float: "left" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.substance.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.substance, "id", $$v)
                      },
                      expression: "substance.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物质类型:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px", float: "left" },
                      attrs: { placeholder: "请选择物质类型" },
                      model: {
                        value: _vm.substance.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.substance, "type", $$v)
                        },
                        expression: "substance.type",
                      },
                    },
                    _vm._l(_vm.substanceTypeOptions, function (item) {
                      return _c("el-option", {
                        key: item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.substance.dkbSubstanceName, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: "substance-name-" + index,
                    attrs: {
                      label: index == 0 ? "物质名称:" : "",
                      rules: [
                        {
                          required: true,
                          message: "请输入物质名称",
                          trigger: "blur",
                        },
                        {
                          validator: _vm.validateSubstanceName,
                          trigger: "blur",
                        },
                      ],
                      prop: "dkbSubstanceName." + index + ".name",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "450px", float: "left" },
                      attrs: {
                        placeholder: "请输入物质名称",
                        maxlength: "300",
                        clearable: "",
                        "show-word-limit": "",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.autoFillUnii(index)
                        },
                      },
                      model: {
                        value: item.name,
                        callback: function ($$v) {
                          _vm.$set(item, "name", $$v)
                        },
                        expression: "item.name",
                      },
                    }),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "80px", float: "left" },
                        attrs: { placeholder: "请选择语言" },
                        model: {
                          value: item.nameLangue,
                          callback: function ($$v) {
                            _vm.$set(item, "nameLangue", $$v)
                          },
                          expression: "item.nameLangue",
                        },
                      },
                      _vm._l(_vm.languageConcep, function (item) {
                        return _c("el-option", {
                          key: item.conceptId,
                          attrs: {
                            label: item.conceptVal,
                            value: item.conceptId,
                          },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { float: "left", "margin-bottom": "0px" },
                        attrs: {
                          rules: [
                            {
                              required: true,
                              message: "请选择名称类型",
                              trigger: "blur",
                            },
                            {
                              validator: _vm.validateAliasType,
                              trigger: "blur",
                            },
                          ],
                          prop: "dkbSubstanceName." + index + ".aliasType",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "120px", float: "left" },
                            attrs: { placeholder: "请选择类型" },
                            on: {
                              change: function ($event) {
                                return _vm.autoFillUnii(index)
                              },
                            },
                            model: {
                              value: item.aliasType,
                              callback: function ($$v) {
                                _vm.$set(item, "aliasType", $$v)
                              },
                              expression: "item.aliasType",
                            },
                          },
                          _vm._l(_vm.aliasTypeConcept, function (item) {
                            return _c("el-option", {
                              key: item.conceptId,
                              attrs: {
                                label: item.conceptVal,
                                value: item.conceptId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("i", {
                      staticClass: "el-icon-remove",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.delSubstanceName(item)
                        },
                      },
                    }),
                    _c(
                      "el-radio",
                      {
                        staticStyle: {
                          "margin-right": "10px",
                          padding: "8px 8px 0 5px",
                        },
                        attrs: { label: index, border: "", size: "small" },
                        on: {
                          change: function ($event) {
                            return _vm.primaryChange($event, "name", index)
                          },
                        },
                        model: {
                          value: _vm.substance.namePrimary,
                          callback: function ($$v) {
                            _vm.$set(_vm.substance, "namePrimary", $$v)
                          },
                          expression: "substance.namePrimary",
                        },
                      },
                      [_vm._v(" 首选项 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showResourceTable(
                              "dkb_substance_name",
                              "dkbSubstanceName",
                              index,
                              "name"
                            )
                          },
                        },
                      },
                      [_vm._v("添加来源 ")]
                    ),
                    index == _vm.substance.dkbSubstanceName.length - 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "addData",
                            staticStyle: {
                              width: "80px",
                              padding: "0px 0px 0px 16px",
                            },
                            attrs: { type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.addSubstanceName("中文")
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-circle-plus",
                              staticStyle: {
                                color: "#7C9DF7",
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                float: "left",
                              },
                            }),
                            _c("svg-icon", {
                              staticStyle: { width: "24px", height: "16px" },
                              attrs: { "icon-class": "china" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    index == _vm.substance.dkbSubstanceName.length - 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "addData",
                            staticStyle: {
                              width: "80px",
                              padding: "0px 0px 0px 16px",
                            },
                            attrs: { type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.addSubstanceName("英语")
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-circle-plus",
                              staticStyle: {
                                color: "#7C9DF7",
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                float: "left",
                              },
                            }),
                            _c("svg-icon", {
                              staticStyle: { width: "24px", height: "16px" },
                              attrs: { "icon-class": "yingguoguoqi" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._l(_vm.substance.dkbSubstanceDesc, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: "substance-desc-" + index,
                    attrs: {
                      label: index == 0 ? "物质描述:" : "",
                      prop: "dkbSubstanceDesc." + index + ".substanceDesc",
                      rules: [
                        {
                          required: true,
                          message: "请输入物质描述",
                          trigger: "blur",
                        },
                        {
                          validator: _vm.validateSubstanceDesc,
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "subDescribe",
                      staticStyle: { width: "500px", float: "left" },
                      attrs: {
                        type: "textarea",
                        rows: 6,
                        placeholder: "请输入物质描述",
                        maxlength: "200",
                        clearable: "",
                        "show-word-limit": "",
                      },
                      model: {
                        value: item.substanceDesc,
                        callback: function ($$v) {
                          _vm.$set(item, "substanceDesc", $$v)
                        },
                        expression: "item.substanceDesc",
                      },
                    }),
                    _c("i", {
                      staticClass: "el-icon-remove",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.delSubstanceDesc(item)
                        },
                      },
                    }),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: index, border: "" },
                        on: {
                          change: function ($event) {
                            return _vm.primaryChange($event, "desc", index)
                          },
                        },
                        model: {
                          value: _vm.substance.descPrimary,
                          callback: function ($$v) {
                            _vm.$set(_vm.substance, "descPrimary", $$v)
                          },
                          expression: "substance.descPrimary",
                        },
                      },
                      [_vm._v(" 设置为推荐值 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.showResourceTable(
                              "dkb_substance_desc",
                              "dkbSubstanceDesc",
                              index,
                              "substance_desc"
                            )
                          },
                        },
                      },
                      [_vm._v("添加来源 ")]
                    ),
                  ],
                  1
                )
              }),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "addData",
                      staticStyle: { width: "500px" },
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.addSubstanceDesc },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus el-icon--left",
                        staticStyle: {
                          color: "#7C9DF7",
                          width: "16px",
                          height: "16px",
                          display: "inline-block",
                        },
                      }),
                      _vm._v(" 添加物质描述 "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联物质:", prop: "relevanceId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "500px" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入关键字搜索",
                        "remote-method": _vm.queryRelevanceSubstance,
                        loading: _vm.loading,
                      },
                      model: {
                        value: _vm.substance.relevanceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.substance, "relevanceId", $$v)
                        },
                        expression: "substance.relevanceId",
                      },
                    },
                    _vm._l(_vm.relevanceSubstanceList, function (item) {
                      return _c("el-option", {
                        key: item.substanceId + "relevanceSubstanceList",
                        attrs: { label: item.name, value: item.substanceId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "title-bar", attrs: { span: 24, id: "a02" } },
                [_vm._v("UNII物质信息")]
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-bottom": "10px" },
                      attrs: { span: 12, offset: 2 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { background: "#2B60F8" },
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.queryUnii },
                        },
                        [_vm._v("UNII查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "warning",
                            icon: "el-icon-delete",
                            plain: "",
                          },
                          on: { click: _vm.clearUnii },
                        },
                        [_vm._v("清除 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", {
                    staticStyle: { height: "180px" },
                    attrs: { span: 2 },
                  }),
                  _c(
                    "el-col",
                    { staticStyle: { height: "180px" }, attrs: { span: 3 } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "150px",
                            height: "150px",
                            background: "#EBF0FA",
                            border: "1px solid #BDD3FF",
                          },
                        },
                        [
                          !_vm.substance.dkbSubstanceStructure.structuralPicture
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: require("@/assets/images/no-image.jpg"),
                                  alt: "image",
                                },
                              })
                            : _vm._e(),
                          _vm.substance.dkbSubstanceStructure.structuralPicture
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src:
                                    "data:image/png;base64," +
                                    _vm.substance.dkbSubstanceStructure
                                      .structuralPicture,
                                  alt: "image",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { height: "auto" }, attrs: { span: 19 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "UNII:" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.substanceCode.unii) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c("el-form-item", { attrs: { label: "CAS:" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.substanceCode.cas) + " "
                                ),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.substanceCode.unii &&
                                        !_vm.substanceCode.cas,
                                      expression:
                                        "substanceCode.unii && !substanceCode.cas",
                                    },
                                  ],
                                  staticClass: "el-icon-edit cas-edit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editCas(
                                        _vm.substance.id,
                                        _vm.substanceCode.unii
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "立体化学:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.substance.dkbSubstanceStructure
                                          .stereochemistry
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "分子式:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.substance.dkbSubstanceStructure
                                          .molecularFormula
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "旋光性:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.substance.dkbSubstanceStructure
                                          .opticalActivity
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "分子量:" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.substance.dkbSubstanceStructure
                                          .molecularWeight
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "120px" },
                      attrs: { span: 20 },
                    },
                    [
                      _c("el-form-item", { attrs: { label: "SMILES:" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.substance.dkbSubstanceStructure.smiles) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "120px" },
                      attrs: { span: 20 },
                    },
                    [
                      _c("el-form-item", { attrs: { label: "INCHI_KEY:" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.substance.dkbSubstanceStructure.inchiKey
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "120px" },
                      attrs: { span: 20 },
                    },
                    [
                      _c("el-form-item", { attrs: { label: "INCHI:" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.substance.dkbSubstanceStructure.inchi) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "title-bar", attrs: { span: 24, id: "a03" } },
                [_vm._v("物质属性")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "", prop: "tableName", id: "recommendProps" },
                },
                _vm._l(_vm.substanceProps, function (item) {
                  return _c(
                    "el-button",
                    {
                      key: "substancePropsButton-" + item.conceptId,
                      attrs: { plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.addSubstanceProp($event, item.conceptId)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.conceptVal) + " ")]
                  )
                }),
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "margin-left": "80px",
                    "padding-bottom": "10px",
                    "font-size": "14px",
                  },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "200px",
                        float: "left",
                        "text-align": "center",
                      },
                    },
                    [_vm._v("物质属性名称")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "200px",
                        float: "left",
                        "text-align": "center",
                      },
                    },
                    [_vm._v("物质属性值")]
                  ),
                ]
              ),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _vm._l(
                    _vm.substance.dkbSubstanceProps,
                    function (item, index) {
                      return _c(
                        "el-form-item",
                        {
                          key: "substance-prop-" + index,
                          attrs: {
                            prop: "dkbSubstanceProps." + index + ".name",
                            rules: [
                              {
                                validator: _vm.validateSubstanceProps,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px", float: "left" },
                              attrs: { placeholder: "请选择物质属性名称" },
                              on: {
                                change: function ($event) {
                                  return _vm.propsNameSelectChange(
                                    $event,
                                    index
                                  )
                                },
                              },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            },
                            _vm._l(_vm.substanceProps, function (item) {
                              return _c("el-option", {
                                key: item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px", float: "left" },
                              attrs: {
                                placeholder: "请选择物质属性值",
                                disabled: item.name === null ? true : false,
                                filterable: "",
                                "filter-method": function (val) {
                                  _vm.substancePropsValFilter(
                                    val,
                                    _vm.substance.dkbSubstanceProps[index].name
                                  )
                                },
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.substancePropsValOptions[
                                    _vm.substance.dkbSubstanceProps[index].name
                                  ] =
                                    _vm.substancePropsVal[
                                      _vm.substance.dkbSubstanceProps[
                                        index
                                      ].name
                                    ]
                                },
                              },
                              model: {
                                value: item.value,
                                callback: function ($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value",
                              },
                            },
                            _vm._l(
                              _vm.substancePropsValOptions[
                                _vm.substance.dkbSubstanceProps[index].name
                              ],
                              function (item) {
                                return _c("el-option", {
                                  key: item.conceptId,
                                  attrs: {
                                    label: item.conceptVal,
                                    value: item.conceptId,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                          _c("i", {
                            staticClass: "el-icon-remove",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.delSubstanceprop(item)
                              },
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showResourceTable(
                                    "dkb_substance_props",
                                    "dkbSubstanceProps",
                                    index,
                                    "name"
                                  )
                                },
                              },
                            },
                            [_vm._v(" 添加来源 ")]
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "addData",
                          staticStyle: { width: "400px" },
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.addSubstanceProp },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus el-icon--left",
                            staticStyle: {
                              color: "#7C9DF7",
                              width: "16px",
                              height: "16px",
                              display: "inline-block",
                            },
                          }),
                          _vm._v(" 添加 "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-col",
            {
              staticClass: "bottom-bar",
              staticStyle: {
                height: "80px",
                "padding-right": "0px",
                position: "fixed",
                right: "30px",
                bottom: "10px",
              },
              attrs: { span: 20 },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.substance.id,
                      expression: "substance.id",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editNext },
                },
                [_vm._v("下一个")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.substance.id,
                      expression: "substance.id",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editPrev },
                },
                [_vm._v("上一个")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.returnPageNoRefresh },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(1)
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(2)
                    },
                  },
                },
                [_vm._v("保存并关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "UNII物质检索",
            width: "60%",
            visible: _vm.unii.uniiTableVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.unii, "uniiTableVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uniiQueryForm",
              attrs: { "status-icon": "", model: _vm.unii.form },
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px", float: "left" },
                      attrs: {
                        title:
                          "搜索匹配方式仅适用于别名,unii和cas编码都是精确匹配",
                      },
                      model: {
                        value: _vm.unii.form.queryType,
                        callback: function ($$v) {
                          _vm.$set(_vm.unii.form, "queryType", $$v)
                        },
                        expression: "unii.form.queryType",
                      },
                    },
                    _vm._l(_vm.queryTypeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: { width: "400px", float: "left" },
                    attrs: {
                      placeholder: "请输入unii编码/cas编码/别名",
                      maxlength: "250",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.queryUnii($event)
                      },
                    },
                    model: {
                      value: _vm.unii.form.searchValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.unii.form, "searchValue", $$v)
                      },
                      expression: "unii.form.searchValue",
                    },
                  }),
                  _c("el-input", { staticStyle: { display: "none" } }),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        background: "#2B60F8",
                      },
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.queryUnii },
                    },
                    [_vm._v("检索 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.unii.uniiTableData,
                height: 300,
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "unii", label: "unii编码", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { property: "cas", label: "CAS", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { property: "displayName", label: "名称" },
              }),
              _c("el-table-column", {
                attrs: { property: "displayName", label: "别名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              trigger: "click",
                              placement: "top",
                              width: "400",
                            },
                          },
                          [
                            _vm._l(scope.row.aliasList, function (item, index) {
                              return _c(
                                "p",
                                { key: "unii" + scope.row.id + "_" + index },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "name-wrapper",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#2B60F8",
                                      "font-weight": "700",
                                    },
                                    attrs: { title: "点击查看全部别名" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.aliasList
                                            ? scope.row.aliasList[0].name
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.chooseUniiData(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isUnii === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.unii.total > 0,
                expression: "unii.total>0",
              },
            ],
            attrs: {
              total: _vm.unii.total,
              page: _vm.unii.form.pageNum,
              limit: _vm.unii.form.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.unii.form, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.unii.form, "pageSize", $event)
              },
              pagination: _vm.queryUnii,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "引用来源管理",
            visible: _vm.resourceTableVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.resourceTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                      },
                      on: { click: _vm.addResource },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "resourceTableForm",
              attrs: { "status-icon": "", model: _vm.resourceTableData },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.resourceTableData.list,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { property: "date", label: "引用资源类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "list." +
                                    scope.$index +
                                    "." +
                                    "resourceTypeId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择物质名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "引用资源类型",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resourceTypeChange(
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.resourceTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "resourceTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.resourceTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.resourceTypeOption,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: "resourceTypeOption" + dict.id,
                                        attrs: {
                                          label: dict.typeName,
                                          value: dict.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "name",
                      align: "center",
                      label: "引用资源名称",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": function (query) {
                                    _vm.resourceNameQuery(
                                      query,
                                      scope.row.resourceTypeId
                                    )
                                  },
                                  loading: _vm.loading,
                                  disabled: !scope.row.resourceTypeId
                                    ? true
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resourceNameChange(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.resourceId,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "resourceId", $$v)
                                  },
                                  expression: "scope.row.resourceId",
                                },
                              },
                              [
                                _vm._l(_vm.resourceDataOption, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                _vm._l([scope.row], function (item) {
                                  return _c("el-option", {
                                    key: item.resourceId + "original",
                                    attrs: {
                                      label: item.resourceName,
                                      value: item.resourceId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#FF3333" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResourceDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-check",
                        size: "small",
                      },
                      on: { click: _vm.saveResource },
                    },
                    [_vm._v("确定 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { icon: "el-icon-circle-close", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.resourceTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }